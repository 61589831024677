<!--
 * @Author: yuwenwen
 * @Date: 2023-02-10 10:11:51
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-03-16 16:51:17
 * @FilePath: \nc-funeral-mobile\src\components\QrcodeReader.vue
-->
<template>
  <div class="qrcode">
    <div class="code">
      <!-- decode是扫描结果的函数，torch用于是否需要打开手电筒，init用于检查该设备是否能够调用摄像头的权限，camera可用于打开前面或者后面摄像头  -->
      <qrcode-drop-zone @decode="onDecode" class="qrcode-zone">
        <qrcode-stream class="qrcode-scan" @decode="onDecode" :torch="torchActive" @init="onInit" :camera="camera" />
      </qrcode-drop-zone>
      <img class="scan" src="@assets/images/scan.png">
    </div>
  </div>
</template>

<script>
// 引用vue-qrcode-reader插件
import { QrcodeStream, QrcodeDropZone } from 'vue3-qrcode-reader'

export default {
  name: 'Approve',
  props: {
    camera: {
      type: String,
      default: 'rear',
    },
    torchActive: {
      type: Boolean,
      default: false,
    },
    qrcode: {
      type: Boolean,
      default: false,
    },
    noStreamApiSupport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  created() {},

  components: {
    // 注册组件
    QrcodeStream,
    QrcodeDropZone,

  },
  methods: {
    // 扫码结果回调
    onDecode(result) {
      this.$emit('onDecode', result)
    },
    // 相机反转
    switchCamera() {
      this.$emit('switchCamera')
    },
    // 关闭相机？？？？？？
    turnCameraOff() {
      this.$emit('turnCameraOff')
    },
    // 打开手电筒
    ClickFlash() {
      this.$emit('ClickFlash')
    },
    // 检查是否调用摄像头
    onInit(promise) {
      this.$emit('onInit', promise)
    },
  },
}
</script>
<style scoped>
.code{
  width: 100vw;
  height: 100vh;

  position: relative;
}

.qrcode-zone{
  width: 100vw;
  height: 100vh;
}

.scan{
  width: 180px;
  height: 180px;
  position: absolute;
  top: calc((100vh - 180px) / 2);
  left:calc((100vw - 180px) / 2);
}


</style>


